'use strict';

angular.module('enervexSalesappApp').controller('AccountLocationCtrl', function($scope, $state, $stateParams, _, AccountTerritoriesService, Contact, Auth, AccountLocation) {
	$scope.accountId = $stateParams.accountId;
	$scope.accountLocationId = $stateParams.accountLocationId;
	$scope.isAdmin = Auth.isAdmin
	$scope.ctx = {
		status: 'requested'
	}
	$scope.statuses = [{
		id:'requested', 
		name: 'Pending Requests'
	},{
		id:'all', 
		name: 'All Requests'
	}]
	$scope.filterStatus = function() {
		$scope.accountTerritoryRequests = _.filter($scope.allAccountTerritoryRequests, function(request){
			return $scope.ctx.status == 'all' || $scope.ctx.status == request.status
		})
	}

	var counties = [];
	
	Contact.query({
		accountId: $stateParams.accountId
	}).$promise.then(function(contacts){
		$scope.contacts = contacts;
	});
	AccountTerritoriesService.getAccount().$promise.then(function(res){
		$scope.account = res;
	});

	AccountTerritoriesService.getStates().$promise.then(function(res){
		$scope.states = res;
	});

	if ($scope.accountLocationId != "new"){
		AccountTerritoriesService.getAccountLocation().$promise.then(function(res){
			$scope.accountLocation = res;
		});
		AccountTerritoriesService.getTerritories().$promise.then(function(territories){
			_.each(territories,function(territory){
				if (territory.coverage === 'county'){
					counties.push(territory.county);
				}
			});
			$scope.groupedState = _.groupBy(counties, function(county){
				try {
					return county.state.state;
				}catch(e){
					return "ERROR"
				}
			});
			$scope.activeTerritories = territories;
		});

		AccountTerritoriesService.getRequests().$promise.then(function(res){
			$scope.allAccountTerritoryRequests = res;
			$scope.filterStatus()
		});
	} else {
		$scope.accountLocation = {}
	}
	$scope.deleteLocation = function() {
		AccountLocation.remove({
			accountId: $scope.accountId,
			id: $scope.accountLocationId
		}).$promise.then(function() {
			$state.go('account-locations', {
				accountId: $scope.accountId
			}, {
				reload: true,
				inherit: false
			})
		})
	}

	$scope.updateAddress = function() {
		if ($scope.accountLocationId != "new"){
			AccountTerritoriesService.updateAccountLocation($scope.accountLocation).$promise.then(function(res){
				$state.go('account-locations', {
					accountId: $scope.accountId
				}, {
					reload: true,
					inherit: false
				})
			});
		} else {
			AccountTerritoriesService.saveNewLocation($scope.accountLocation).$promise.then(function(res){
				$state.go('account-locations', {
					accountId: $scope.accountId
				}, {
					reload: true,
					inherit: false
				})
			});
			
		}
	};
});
